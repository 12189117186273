[data-page-name="certif-request-form"] {
	header{
		.description{
			position: relative;
			z-index: 4;
		}
	}
	.top-form{ margin-top: calc(-185px / 2);}
	.top-form, .form{
		position: relative;
		z-index: 3;
		h2{
			margin-bottom: $spacefix;
			&.title-only{margin-bottom:0;}
		}
		.field-container{
			.select-container, input{
				width: 100%;
			}
			&:last-child {
				margin-bottom: auto;
			}
		}
	}
	.submit-section{
		margin-top: $spacefix;
		margin-bottom: $spacefix;
		.container{
			display: flex;
			justify-content: flex-end;
		}
	}
	.flash-danger {
		color: $red;
		font-weight: bold;
	}
	.error_form{
		color: $red;
	}

	.tab {
		.tab-item {
			cursor: pointer;
		}
		.active {
			color: $bluedarker;
			font-weight: bolder;
			font-size: 1.3em;
		}
	}

}

[data-page-name="certif-request-modification"]{

	[name^="formResponse"]{
		legend, label {font-family: 'Open Sans', Arial, sans-serif;}
		&>.form{
			h2:first-child{
				display: none;
			}

			// First level
			&>fieldset{
				border: none;
				position: relative;
				padding: 30px 0;
				&>legend{
					position: relative;
					font-family: 'Bebasneue', Arial, sans-serif  !important;
					text-transform: uppercase;
					width: 100%;
					font-size: 30px;
					line-height: 30px;
					font-weight: 700;
					color: $greydarker;
					transform: translateY(40px);
					padding-bottom: 10px;
					margin-bottom: 20px;
					&::before{
						content: '';
						width: 100%;
						height: 3px;
						position: absolute;
						bottom: -5px;
						left: 0px;
						background: $orange;
					}
				}
				table{margin: 0;}
				// Second level
				&>fieldset{
					background: $whitedarker;
					border: none;
					border-radius: 4px;
					padding: 30px 0;
					margin-bottom: 10px;
					box-shadow: 0px 6px 20px 0px rgba(0,0,0,0.1);
					&>legend{
						font-family: 'Open Sans', Arial, sans-serif;
						color: $greydarker;
						font-size: 30px;
						line-height: 30px;
						font-weight: 700;
						transform: translateY(40px);
						padding-bottom: 10px;
					}

					// third level
					&>fieldset{
						border: none;
						background: #ffffff;
						padding: 15px;
						padding-top: 20px; 
					    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.2);
						&>legend{
							font-family: 'Open Sans', Arial, sans-serif;
							color: $greydarker;
							font-size: 23px;
							line-height: 30px;
							font-weight: 700;
							transform: translateY(30px);
							padding-bottom: 10px;
						}
						
						// Fourth level
						&>fieldset{
							border: none;
							margin-top: 20px;
							&>legend{
								font-family: 'Open Sans', Arial, sans-serif;
								color: $greydarker;
							    font-size: 18px;
    							font-weight: 600;
    							transform: translateY(15px);
							}
						}
					}
				}
				[id^="formResponse_formFieldResponses_"]{
					label.required{
						font-family: 'Bebasneue', Arial, sans-serif;
						font-size: 18px;
						padding-left: 0px;
						&:after{
							content: '(Mandatory)';
							font-family: 'Open Sans', Arial, sans-serif;
							font-weight: 300;
							font-size: 11px;
							line-height: 24px;
							margin-left: 10px;
						}
					}
					.add-another-collection-widget{
						width: 100%;
						border-radius: 0px 0px 4px 4px;
						user-select: none;
					}
				}
			}
			div.field-container{
				&>div>.checkbox_container:first-child{
					margin-top: 15px;
				}
				&>div[id^="formResponse_formFieldResponses"]{
					&>div:not(.checkbox_container){
						margin-top: $smallspacefix * 1.5;
					}
				}
			}
			
		}
	}

	.submit-section{
		display: flex;
		justify-content: flex-end;
		.btn-classic-accordeon{
			margin-left: $smallspacefix;
		}
	}
}

form {
	.hidden-fields {
		display: none;
		height: 0;
	}

	.form-collection-table {
		border: 1px solid #d6d6d6;

		tr {
			td {
				padding: 10px;
			}
		}
	}
}


.process-time-line{
	display: flex;
	position: relative;
	width: calc(100% - 200px);
	justify-content: space-between;
	margin-top: $spacefix;
	margin-bottom: $spacefix;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	&:after{
		content:"";
		position: absolute;
		z-index: 1;
		width: 100%;
		height: 1px;
		left: 0;
		top: 10px;
		background: $greylighter;
	}
	.step{
		position: relative;
		padding-top: $spacefix * 2;
		font-weight: 700;
		&:after{
			content: '';
			position: absolute;
			z-index: 6;
			width: 20px;
			height: 20px;
			top: 0;
			left: calc(50% - 10px);
			border-radius: 60px;
			background: $blue;
		}
		&.active{
			&:before{
				content: '';
				position: absolute;
				width: 50px;
				height: 50px;
				left: calc(50% - 25px);
				top: -10px;
				z-index: 9;
				background: url(/assets/pictos/pointeur.svg);
			}
		}
	}

	a { color:darken($blue, 10%);
		&:hover {
			color:$blue;
		}
	}
}


.certificat-request-risk-assessment-table {
	tr {
		td {
			padding: 5px 5px;

			div {
				border: none !important;
				padding: 5px !important;
			}
		}
	}
}

.certificat-request-risk-assessment-table-form-begin {
	tr {
		td {
			padding: 5px 5px!important;

			span.select2 {
				width: auto !important;
			}

			span.select2-selection__arrow{
				display: none;
			}

			span.select2-selection {
				border: none !important;
				padding: 5px !important;
				background-color: inherit !important;
				height: auto !important;
			}

			span.select2-selection__rendered {
				white-space: break-spaces!important;
				line-height: 25px!important;
			}
		}
	}
}

