[data-page-name="profile"] {
    [data-description="name"] {
        color: $black;
        font-weight: 700;
    }
    [data-description="mail"] {

    }
    .top-description {
        margin-bottom: $spacefix;
    }
}

[data-page-name="reset-password"], [data-page-name="profile"] {
    .list-unstyled {
        li {
            color: red;
            font-weight: 800;
        }
    }
}
[data-page-name="certif-request-detail"] {
  .item-comment {
    position: relative;
    .comment-badge {
      position: absolute;
      bottom: -10px;
      right: -10px;
      font-size: 1.1em;
      background: $bluedarker;
      color: white;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      border-radius: 50%;
      box-shadow: 0 0 1px #333;
      z-index: 1;
    }

  }
}

[data-page-name="comments"] {
    .list-comments {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 2em;
      #picto-message {
        width: 50px;
        height: auto;
      }
      .message_item {
        width: 85%;
        margin-bottom: 1.5em;
        margin-top: 1.5em;
        .top {
          display: flex;
          font-weight: 800;
          margin-bottom: 1em;
          .new_comment {
            align-self: flex-end;
            background-color: $bluedarker;
            color: #fff;
            padding: 0.5em 1.5em;
            margin-left: auto;
          }
          .author {
            margin: auto 0;
          }
        }
        .message {
          line-height: 1.5em;
        }
        .new_message {
          font-weight: bold;
        }
        &.content_right {
          align-self: flex-end;
          .top {
            text-align: right;
            display: block;
          }
        }
        &.content_left {
          align-self: flex-start;
        }
      }
      .border-bottom {
        width: 60%;
        border: 1px solid rgba(183,183,183,0.19);
        margin: 0 auto;
      }
    }
  #post-comment {
    padding-top: 2em;
    button[type="submit"] {
      margin-top: 2em;
    }
  }
}

[data-page-name="certif-request-update-extension"] {
    #action-update {
        display: flex;
        .btn-classic {
            flex: 1;
            margin: 0 1em;
            background: $orange;
        }
    }
}
