.form-summary {

  .form-summary-title {
    margin-top: $spacefix*2;
    margin-bottom: $spacefix;
    padding-bottom: $smallspacefix;

    width: 100%;
    border-bottom: 2px solid $bluedarker;
  }

  .form-summary-table {

    th {
      width: 15vw;
      text-align: left;
    }

    td {
      text-align: left;
    }

    table {
      border: 1px solid $bluedarker;
    }
  }
}

// V2 :
.form-group {
  margin-bottom :  5px;
  textarea,
  input {
    color: $grey;
    font-size: 14px;
    border-radius: 4px;
    padding: 5px;
    background: $whitelighter;
    border: 1px solid $greylighter;
    width: 100%;
    @include transition-linear();
    &:focus {
        @include transition-linear();
        box-shadow: 0px 0px 8px -2px $blue;
        border: solid 1px $blue;
    } 
    &:disabled, &:read-only {
      background-color: #efefef;
    }
  }
  .select2.select2-container .select2-selection {
    border: 1px solid $greylighter;
    height: 50px;
  }
  .select2.select2-container .select2-selection .select2-selection__rendered {
    line-height: 48px;
  }
  .select2.select2-container .select2-selection .select2-selection__arrow {
    height: 48px;
    width: 34px;
  }
  .list-unstyled {
    label {
      margin-bottom: 0;
    }
    li {
      margin-bottom: 15px;
    }
  }
  label {
    display:inline-block;
    font-size: 14px;
    font-weight: bold;
    color: $grey;
    width: 100%;
    margin-bottom: 10px;
  }
  legend label {
    margin-bottom: 0;
  }
  select[readonly].select2-hidden-accessible + .select2-container {
    pointer-events: none;
    touch-action: none;
  }
  select[readonly].select2-hidden-accessible + .select2-container .select2-selection {
    background-color: #efefef;
  }
}

/** Table collection */
.collection td {
  vertical-align:top
}
.collection tr .help-text {
  display: none;
}
.collection tr:last-child .help-text {
  display: block;
}


.form-collection-table label {
  color: #333;
  margin-bottom: 0;
}

.help-text {
  margin-top: $spacefix / 3;
  padding-left: $spacefix;
  margin-bottom: 20px;
  color: $grey;
  font-style: italic;
  position: relative;

  &::before {
      content: "?";
      border: 1px solid $grey;
      border-radius: 50%;
      color: $grey;
      width: 20px;
      position: absolute;
      height: 20px;
      text-align: center;
      left: 0;
      top: 0;
  }
}
/** btns */
.add-another-collection-widget {
    width : 100%;
    border-radius: 0;
}

.btn-danger, .btn-info {
  border: 0;
  color: $white;
  border-radius: 5px;
  padding: 15px 25px;
  font-weight: 700;
  cursor: pointer;
  @include transition-linear();
}

.btn-danger {
  background: $red;
  &:hover {
      background: $reddarker;
  }
}

.btn-info {
  background-color: $blue;
  &:hover {
    background: $bluedarker;
  }
}

fieldset.form-group {
  border: 0;
  padding: 0!important;
  margin:10px 0 15px 0;

  legend, legend label {
    font-family: 'Bebasneue', Arial, sans-serif !important;
    text-transform: uppercase;
    width: 100%;
    font-size: 30px;
    line-height: 30px;
    font-weight: 700;
    color: $greydarker;
    transform: translateY(0)!important;
    padding: 0 !important;
    margin: 0 !important;
  }

  legend {
    margin: 0 0 20px 0 !important;
    &::before{
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: -5px;
      left: 0px;
      background: #a0a4a7;
    }
  }
}

fieldset.form-group .form-group:last-child,
div.form-group[id$='files'] {
  margin-bottom : 0 !important;
}

// Questionnaire tab
.questionnaire .tab {
  overflow: hidden;
  //border: 1px solid #ccc;
  background-color: #f1f1f1;
  margin-bottom: 10px;
}

 .questionnaire .tab button {
  background-color: inherit;
  font-weight: bold;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 16px 25px;
  transition: 0.3s;
}

.s-checkboxes > div,
.gs-checkboxes > div {
  display: inline-block;
  margin-right: 50px;
}
.s-checkboxes > div  div,
.gs-checkboxes > div  div {
  display:block;
  float:left;
  margin-right: 10px;
}
.s-checkboxes > div  label,
.gs-checkboxes > div  label {
  display: block;
  white-space: nowrap;
}

.panel {
  border: 1px solid $blue;
  margin-bottom: 15px;
  .panel-heading {
    color: $white;
    background-color: $blue;
    padding: 10px;
  }

  .panel-body {
    padding: 10px;
  }
}

.questionnaire .tab button:hover {
  background-color: #ddd;
}

.questionnaire .tab button.active {
  color: #fff;
  background-color: $blue;
}

.questionnaire .tabcontent {
    display: none;
    padding: 10px 0;
}

.questionnaire .tabcontent.active {
    display: block;
}

.has-error {
  legend label,
  > label {
    color : #dd4b39 !important;
  }
  li {
    color : #dd4b39;
    i { display: none;}
  }
}

.alert {
  border: 0;
  color: #fff;
  font-weight: 700;

  &.alert-danger {
    background-color : #dd4b39;
  }
}

.subtitle {
  color: #333 !important;
  font-size: 22px !important;
}
