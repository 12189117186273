// ==================================================
// FONTS IMPORTS
// ==================================================

@font-face {
    font-family: 'Bebasneue';
    src: url('/assets/fonts/bebas_neue/BebasNeue_Regular.ttf'),
    url('/assets/fonts/bebas_neue/BebasNeue_Regular.otf');
    font-weight: normal;
}

@font-face {
    font-family: 'Open sans';
    src: url('/assets/fonts/open_sans/OpenSans-Regular.ttf'),
    url('/assets/fonts/open_sans/OpenSans-Regular.otf');
    font-weight: normal;
}

@font-face {
    font-family: 'Open sans';
    src: url('/assets/fonts/open_sans/OpenSans-Bold.ttf'),
    url('/assets/fonts/open_sans/OpenSans-Bold.otf');
    font-weight: 700;
}

// ==================================================
// ANIMATIONS MIXINS
// ==================================================

@mixin transition-linear($time: 'auto') {
    @if $time == 'auto' {
        transition: 0.2s ease-in;
    } @else {
        transition: $time ease-in;
    }
}

// ==================================================
// FUNCTIONNAL MIXINS
// ==================================================
$breakpoints: (
        "phone": 250px,
        "phonemax": 767px,
        "tablet": 768px,
        "tabletmax": 1023px,
        "laptop": 1024px,
        "laptopMax": 1279px,
        "desktop": 1280px,
);
@mixin media-min($_key) {
    @media screen and (min-width: map-get($breakpoints, $_key)) {
        @content;
    }
}

@mixin media-max($_key) {
    @media screen and (max-width: map-get($breakpoints, $_key)) {
        @content;
    }
}

@mixin media-min-max($key1, $key2) {
    @media screen and (min-width: map-get($breakpoints, $key1)) and (max-width: map-get($breakpoints, $key2)) {
        @content;
    }
}

@mixin fullcenterflex {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin marginY {
    @include media-min("phone") {
        margin-bottom: $spaceinsidemobile;
    }
    @include media-min("laptop") {
        margin-bottom: $spaceinside;
    }
}

@mixin full-margin {
    @include media-min("phone") {

    }

}

// ==================================================
// COLORS AND SIZES INITIALIZE
// ==================================================

$whitelighter: rgb(255, 255, 255); // #ffffff
$white: rgb(250, 255, 255); // #f6f8f9
$whitedarker: #f3f3f3; // #f6f8f9

$greylighter: rgb(160, 164, 167); // #a0a4a7
$grey: rgb(110, 110, 110); // #6e6e6e
$greydarker: rgb(51, 51, 51); // #2e2e2e

$black: rgb(0, 0, 0); // #000000

$orange: rgb(239, 98, 61); // #ef623d
$red: rgb(239, 61, 61); //
$reddarker: rgb(196, 44, 44); //

$bluelighter: rgb(228, 243, 254); // #e4f3fe
$blue: rgb(26, 160, 224); // #1aa0e0
$bluedarker: rgb(0, 99, 175); // #0063af

$pictowidth: 20px;

$smallspacefix: 10px;
$spacefix: 30px;

// ==================================================
// CLEAN STYLESHEET
// ==================================================
#width-tester {
    position: fixed;
    top: 0;
    left: 0;
    background: greenyellow;
    padding: 3px;
    font-size: 13px;
    z-index: 99999;
    pointer-events: none;
}

html {
    min-width: 1024px;
    max-width: 100vw;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: dark;
    font-size: 13px;
    scrollbar-color: $bluedarker $whitelighter;
    @include media-max('laptop') {
        overflow-x: scroll;
    }
    body {
        font-family: 'Cabin', sans-serif;
        margin: 0;
        padding: 0;
        min-width: 100vw;
        min-height: 100vh;
        overflow: hidden;
        h3, h4, h5, p {
            margin: 0;
            font-family: 'Cabin', sans-serif;
        }
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                margin: 0;
                padding: 0;
                list-style: none;
            }
        }
    }
}

* {
    box-sizing: border-box;
    outline: none;
    scroll-behavior: smooth;
}

a {
    color: $black;
    text-decoration: none;
}

.link {
    color: $grey;
    position: relative;
    padding-left: 0px;
    padding-bottom: 4px;
    padding-top: 1px;
    @include transition-linear();
    &:hover {
        padding-left: 25px;
        @include transition-linear();
        &:before {
            width: 20px;
            @include transition-linear();
        }
        &:after {
            transform: scaleX(0);
            transform-origin: 0%;
            @include transition-linear();
        }
    }
    &:before {
        content: '';
        position: absolute;
        display: inline-flex;
        box-sizing: border-box;
        background: $orange;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 0px;
        @include transition-linear();

    }
    &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2px;
        width: 100%;
        background: $orange;
        transform: scaleX(1);
        transform-origin: 0%;
        @include transition-linear()
    }
}

.maintit {
    font-family: 'Bebasneue', Arial, sans-serif;
    font-size: 48px;
    font-weight: 500;
    margin-bottom: 53px;
    color: $whitelighter;
}

h1 {
    font-family: 'Bebasneue', Arial, sans-serif;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 41px;
}

h2 {
    font-family: 'Open Sans', Arial, sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: $grey;
    margin: 0;
}

p {
    font-family: 'Open Sans', Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
}

ul {
    li {
        font-family: 'Open Sans', Arial, sans-serif;
        font-size: 14px;
        font-weight: 400;
    }
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 4px;
    position: relative;
    z-index: 0;
}

/* Track */
::-webkit-scrollbar-track {
    background: $greylighter;
    position: relative;
    z-index: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $blue;
    position: relative;
    z-index: 0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $bluedarker;
}

.img {
    width: 100%;
}

.logo {
    max-width: 120px;
    position: relative;
    z-index: 9;
}

.picto {
    min-width: $pictowidth;
    max-width: $pictowidth;
    min-height: $pictowidth;
    max-height: $pictowidth;
}

.picto-close {
    .picto {
        pointer-events: none;
    }
}

.picto-great {
    min-width: $pictowidth*3;
    max-width: $pictowidth*3;
    min-height: $pictowidth*3;
    max-height: $pictowidth*3;
}

.flex-row-container {
    display: flex;
    flex-wrap: nowrap;
}

.flex-row-container-force {
    display: flex;
    flex-direction: row;
}

.flex-column-container {
    display: flex;
    flex-wrap: wrap;
}

.flex-column-container-force {
    display: flex;
    flex-direction: column;
}

// ==================================================
// DEFAULT MARGINS AND PADDINGS
// ==================================================
.padding-standard {
    padding-left: 100px;
    padding-right: 100px;
}

.margin-standard {
    margin-left: 100px;
    margin-right: 100px;
}

.marginY-standard {
    margin-top: $spacefix;
    margin-bottom: $spacefix;
}

.margin-top-small {
    margin-top: $smallspacefix;
}

.margin-top-standard {
    margin-top: $spacefix;
}

// ==================================================
// CLASSES FOR FEW USAGES
// ==================================================
.font-white {
    color: $whitelighter;
}

.bordered-container {
    margin-top: $spacefix;
    padding: $spacefix;
    background: $whitelighter;
    border: 1px solid $greylighter;
    &:last-child {
        margin-bottom: $spacefix;
    }
    .title-part {
        margin-bottom: $spacefix;
    }
    .container-end {
        display: flex;
        justify-content: flex-end;
        .btn-small {
            margin-top: $spacefix;
        }
    }
    .container-space-between {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: $spacefix;

        .btn-small {
            margin-top: $spacefix;
        }
    }

}

.picto-container {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.orange {
        background: $orange;
    }
    &.blue {
        background: $blue;
    }
}

.title-part {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    h2 {
        padding-left: $spacefix;
    }
}

.flex-col-1-1 {
    & > div:nth-child(1) {
        margin-right: $spacefix / 2;
    }
    & > div:nth-child(2) {
        margin-left: $spacefix / 2;
    }
}

.flex-col-1-1-1 {
    & > div:nth-child(1) {
        margin-right: $spacefix / 2;
    }
    & > div:nth-child(2) {
        margin-left: $spacefix / 2;
        margin-right: $spacefix / 2;
    }
    & > div:nth-child(3) {
        margin-left: $spacefix / 2;
    }
}

// ==================================================
// FORMS STYLES
// ==================================================

$checkbox_width: 25px;

.checkbox_container {
    position: relative;
    display: inline-flex;
    width: 49%;
    margin-bottom: 15px;
    &:first-child {
        margin-bottom: 0px;
    }

    .fake_checkbox {
        height: $checkbox_width;
        padding-bottom: 0px;
        padding-left: 0px;
        .fake_checkbox_title {
            display: flex;
            align-items: center;
            padding-left: $checkbox_width + 10px;;
        }
        .fake_checkbox_icon {
            box-sizing: border-box;
            width: $checkbox_width;
            min-width: $checkbox_width;
            height: $checkbox_width;
            min-height: $checkbox_width;
            border: 2px solid $bluedarker;
            background: $bluelighter;
            border-radius: 3px;
            position: absolute;
            top: 0;
            left: 0;

            &:before {
                content: '';
                position: absolute;
                top: $checkbox_width*0.15;
                left: $checkbox_width*0.15;
                height: 0px;
                width: 0px;
                border-left: 3px solid $whitelighter;
                border-bottom: 3px solid $whitelighter;
                opacity: 0;
                transform: rotateZ(-45deg);
            }
        }
    }
    .real_checkbox {
        display: none;
        &:checked + .fake_checkbox {
            .fake_checkbox_icon {
                background: $bluedarker;
                &:before {
                    animation: checkbox_anim_forward 0.2s;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                }
            }
        }
    }
}

@keyframes checkbox_anim_forward {
    0% {
        height: 0px;
        width: 0px;
        opacity: 0;
    }
    1% {
        height: 0px;
        width: 0px;
        opacity: 1;
    }
    50% {
        height: 5px;
        width: 0px;
        opacity: 1;
    }
    100% {
        height: 5px;
        width: 10px;
        opacity: 1;
    }
}

.field-container-checkbox {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: $smallspacefix;
    // label {
    //     padding-left: $spacefix;
    // }
}

.field-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $spacefix;
    width: 100%;
    // &:last-child {
    //     margin-bottom: 0px;
    // }

    & > div {
        display: flex;
        width: 100%;
        & > div {
            width: 100%;
            &:nth-child(2) {
                align-self: flex-end;
                margin-left: $spacefix;
            }
        }
    }

    label {
        //display: flex;
        font-size: 14px;
        font-weight: bold;
        color: $grey;
        width: 100%;
        padding-bottom: $smallspacefix;
        //padding-left: $spacefix / 2;
    }
    input[type="text"],
    input[type="password"],
    input[type="date"],
    input[type="email"],
    textarea {
        color: $grey;
        font-size: 14px;
        border-radius: 4px;
        padding: 15px;
        background: $whitelighter;
        border: 1px solid $greylighter;
        width: 100%;
        @include transition-linear();
        &:focus {
            @include transition-linear();
            box-shadow: 0px 0px 8px -2px $blue;
            border: solid 1px $blue;
        }
    }
    input[type="number"], select, .select2 .select2-selection--multiple {
        background: $white;
        border: 1px solid $greylighter;
        width: 100%;
        padding: 15px 25px 15px 15px;
        min-width: 175px;
        border-radius: 4px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    select, .select2 .select2-selection--multiple, .select2 .select2-selection--multiple{
        display: block;
        max-width: 100%;
        box-sizing: border-box;
        margin: 0;
        background-image: url("/assets/pictos/bottomarrow.svg"),
        linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
        background-repeat: no-repeat, repeat;
        background-position: right .7em top 50%, 0 0;
        background-size: .65em auto, 100%;
        font-size: 14px;
        option {
            font-size: 14px;
        }
    }
    .select2 .select2-selection--multiple {
        padding: 12.5px 25px 12.5px 15px;
    }

    .help-text {
        margin-top: $spacefix / 3;
        padding-left: $spacefix;

        color: $grey;
        font-style: italic;

        position: relative;

        &::before {
            content: "?";
            border: 1px solid $grey;
            border-radius: 50%;
            color: $grey;
            width: 20px;
            position: absolute;
            height: 20px;
            text-align: center;
            left: 0;
            top: 0;
        }
    }
}

.input-file-container {
    width: 100%;
    label {
        background: $bluelighter;
        height: 200px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $black;
        @include transition-linear();
        cursor: pointer;

        &:hover {
            background: $blue;
            @include transition-linear();
            .picto-great {
                filter: invert(1);
                @include transition-linear();
            }
            p {
                color: #ffffff;
                @include transition-linear();
            }
        }
        p, .picto-great {
            transform: scale(1);
            @include transition-linear();
        }
    }
    table.docsAttach {
        margin-top: $spacefix;
        margin-bottom: $spacefix;
        .table-header {
            height: 0px;
            @include transition-linear();
        }
    }
    &.active {
        .table-header {
            height: 0px;
            @include transition-linear();
        }
        label:hover {
            background: $bluelighter;
            p {
                color: $black;
            }
            .picto-great {
                filter: invert(0);
            }
        }
        .picto-great {
            filter: invert(0);
            transform: scale(0.85);
            @include transition-linear(0.8s);
        }
        p {
            color: $black;
            transform: scale(0.85);
            @include transition-linear(0.8s);
        }
    }
    .files-custom {
        display: none;
    }
}

.submit-section {
    margin-top: $spacefix;
    & > * {
        margin-right: $smallspacefix;
        &:last-child {
            margin-right: 0px;
        }
    }
    input[type="submit"],
    button[type="submit"] {
        font-size: 14px;
    }
}

label.required:after {
    content: " *";
    color: red;
    font-weight: bold;
}

// ==================================================
// BUTTONS STYLES
// ==================================================
.btn-small {
    padding: 5px $spacefix;
    min-height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: $whitelighter;
    border: 1px solid $greylighter;
    border-radius: 60px;
    font-weight: 700;
    &.round {
        width: 50px;
        height: 50px;
        padding: 0;
    }

}

.add-another-collection-widget,
.btn-classic {
    padding: 8px $spacefix;
    min-width: 200px;
    min-height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: $bluedarker;
    color: $whitelighter;
    font-size: 16px;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    max-height: 50px;
    @include transition-linear();
    &[type="submit"] {
        background: $orange;
        &:hover {
            background: $orange;
        }
        &.blue {
            background: $bluedarker;
            &:hover {
                background: $blue;
            }
        }
    }
    &:hover {
        background: $blue;
        box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.2);
        @include transition-linear();
    }
    &.white {
        background: $whitelighter;
        color: $black;
        border: 1px solid $greylighter;
        @include transition-linear();
        &:hover {
            background: $white;
            @include transition-linear();
        }
    }
}

.add-another-collection-widget {
    min-width: 100px;
}

.btn-remove {
    background: $red;
    border: none;
    color: $whitelighter;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    height: 50px;
    width: 100px;
    padding: 0px 25px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    @include transition-linear();
    cursor: pointer;
    &:hover {
        background: $reddarker;
        @include transition-linear();
    }
}

.btn-classic-accordeon {
    border: none;
    background: none;
    position: relative;
    display: flex;
    align-items: flex-start;
    &.active {
        .picto-opener {
            border-radius: 0 100px 0px 0;
            @include transition-linear();
        }
        .secondary-menu-btn {
            transform: scaleY(1);
            transform-origin: 0% 0%;
            @include transition-linear();
            transition-delay: 0.2s;
        }
    }
    .main-label-btn {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        border: none;
        color: $whitelighter;
        height: 50px;
        padding-left: $spacefix;
        padding-right: $smallspacefix;
        background: $bluedarker;
        border-radius: 100px 0 0 100px;
        font-weight: 700;
    }
    .picto-opener {
        background: $bluedarker;
        display: inline-flex;
        position: relative;
        height: 50px;;
        align-items: center;
        justify-content: center;
        padding-left: $smallspacefix;
        padding-right: $spacefix;
        border-radius: 0 100px 100px 0;
        cursor: pointer;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        @include transition-linear();
        & > * {
            pointer-events: none;
        }
        &:hover {
            background: $blue;
            @include transition-linear();
            transition-delay: 0.2s;
        }
        &:after {
            content: '';
            background: $whitelighter;
            height: 40px;
            width: 1px;
            left: 0px;
            top: 5px;
            position: absolute;
        }
    }
    .secondary-menu-btn {
        position: absolute;
        width: calc(100% - 30px);
        top: 50px;
        right: 0px;
        overflow: hidden;
        display: flex;
        justify-content: flex-end;
        transform: scaleY(0);
        transform-origin: 0% 0%;
        @include transition-linear();
        li:nth-child(2) {
            display: none;
        }
        button {
            border-radius: 0px 0px 100px 100px;
            width: 100%;
            border: none;
            background: $blue;
            color: $whitelighter;
            padding: $smallspacefix $smallspacefix*2;
            font-size: 14px;
            cursor: pointer;
            box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.3) inset;
            @include transition-linear();
            &:hover {
                box-shadow: 0px 7px 9px 0px rgba(0, 0, 0, 0.3) inset;
                @include transition-linear();
            }
        }
    }
}

// ==================================================
// STYLISH ALL SMALL PAGE'S PARTS
// ==================================================

.top-bar {
    width: 100vw;
    min-height: 50px;
    background: $black;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    .col:nth-child(1) {
        padding-left: 30px;
        display: flex;
        align-items: center;
        color: $whitelighter;
    }
    .col:nth-child(2) {
        display: flex;
        align-items: stretch;
        .disconnect {
            display: flex;
            align-items: center;
            padding-right: $spacefix;
            a.link {
                color: $whitelighter;
            }
        }
        .languages {
            position: relative;
            color: $white;
            min-width: 100px;
            cursor: pointer;
            .accordeon-opener {
                padding: 0 $spacefix*0.5;
                display: flex;
                align-items: center;
                height: 100%;
                justify-content: center;
                background: rgb(46, 46, 46);
                .picto {
                    margin-left: $smallspacefix;
                }
                & > * {
                    pointer-events: none;
                    user-select: none;
                    -moz-user-select: none;
                    -webkit-user-select: none;
                    -ms-user-select: none;
                }
            }
            .accordeon-hidden {
                width: 100%;
                right: 0;
                z-index: 9;
                .row {
                    width: 100%;
                    padding: 8px;
                    text-align: center;
                    cursor: pointer;
                    background: $grey;
                    @include transition-linear();
                    &:hover {
                        @include transition-linear();
                        background: $greydarker;
                    }
                }
            }
        }
    }
}

header[data-type-header="standard-page"] {
    background: url(https://images.unsplash.com/photo-1557162471-08c38b8218e7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80);
    background-size: cover;
    padding-top: $spacefix;
    //padding-bottom: $spacefix * 2 + 10px;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        background: $bluedarker;
        opacity: 0.5;
    }
    h1 {
        position: relative;
        z-index: 1;
        &:after {
            content: '';
            position: absolute;
            bottom: -0.5rem;
            left: 0;
            background: $blue;
            height: 4px;
            width: 70px;
        }
    }
    .text-container {
        position: relative;
        z-index: 9;
        display: flex;
        width: 100%;
        .name {
            margin-top: $smallspacefix;
            font-weight: 900;
            font-size: 20px;
            color: $whitelighter;
        }
        .email {
            font-size: 16px;
            color: $white;
        }
        .icon {
            font-size: 10px;
        }
    }
    .maintit {
        position: relative;
        z-index: 1;
        text-align: center;
        &:after {
            content: '';
            position: absolute;
            bottom: -15px;
            left: calc(50% - 50px);
            background: $blue;
            height: 4px;
            width: 70px;
        }
    }
    .btn-classic {
        position: relative;
        z-index: 3;
    }
    .flex-row-container {
        justify-content: space-between;
    }
    .info-vignet {
        margin-top: $spacefix;
    }
}

.info-vignet {
    padding: $smallspacefix;
    background: $blue;
    color: $whitelighter;
    width: 100%;
    position: relative;
    z-index: 4;
    & > a.link {
        color: $whitelighter;
        font-weight: 700;
    }
}

.flash-message {
    &.info-vignet {
        position: absolute;
        &.flash-error {
            background-color: $red;
        }
    }
    opacity: 1;
    transition: 1s opacity;

}

.top-description {
    padding: $spacefix;
    background: $bluelighter;
    margin-top: calc(-185px / 2);
    position: relative;
    z-index: 2;
    .flex-row-container {
        &.result-description {
            justify-content: space-between;
            padding: $spacefix 0;
            border-bottom: 1px solid $greylighter;
            &:last-child {
                border: none;
            }
        }
    }
    .flex-row-container-force {
        margin-bottom: $smallspacefix;
        &:last-child {
            margin-bottom: 0;
        }
        .flex-row-container {
            align-self: flex-end;
            .reset-field {
                margin-left: $smallspacefix;
                cursor: pointer;
                transform: rotateZ(40deg);
                @include transition-linear(0.4s);
                &:hover {
                    transform: rotateZ(-90deg);
                    @include transition-linear(0.4s);
                }
            }
        }
        .title-description {
            font-weight: 700;
            flex-basis: 20%;
            min-width: 250px;
        }
        .title-description-full {
            font-weight: 700;
            flex-basis: 100%;
            min-width: 250px;
        }
        .title-description-alone {
            font-weight: 700;
        }
        .result-description {
        }
        .result-description-alone {
            margin-left: 10px;
        }
        .field-container {
            margin-right: $spacefix;
            margin-bottom: 0;
            align-self: flex-end;
            .select-container {
                width: 100%;
            }
        }
    }
}

.consult-selection {
    margin-bottom: $spacefix * 2;
    .vignet-selection {
        flex-basis: calc(50% - 30px);
        min-width: calc(50% - 30px);
        min-height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transform: scale(1);
        border: 1px solid $greylighter;
        @include transition-linear();

        &:nth-child(2n + 1) {
            margin-right: $spacefix;
        }
        &:nth-child(2n + 2) {
            margin-left: $spacefix;
        }
        &:nth-child(n-3) {
            margin-top: $spacefix * 2;
        }
        &:nth-child(n + 3) {
            margin-top: $spacefix * 2;
        }

        &:not(.disable) {
            &:hover {
                background: $orange;
                border: 1px solid $orange;
                transform: scale(1.1);
                @include transition-linear();
                .picto-great {
                    filter: invert(1);
                }
                .vignet-title {
                    color: $whitelighter;
                }
            }
        }
        .vignet-title {
            font-family: 'Bebasneue', Arial, sans-serif;
            font-size: 30px;
            font-weight: 700;
            margin-top: $smallspacefix;
        }
        &.disable {
            opacity: 0.5;
            cursor: not-allowed;
            pointer-events: none;
        }

    }
}

.page-container {
    min-height: calc(100vh - (50px * 2));
}

.notification-table-scroll {
    overflow-y: scroll;
    max-height: calc(4 * 50px);
}

table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 0;
    tr.table-header {
        background: $bluedarker !important;
        & > * {
            font-family: 'Open Sans', Arial, sans-serif;
            font-size: 14px;
            font-weight: 400;
            color: $white;
            text-align: left;
        }
        a {
            font-family: 'Open Sans', Arial, sans-serif;
            font-size: 14px;
            font-weight: 400;
            color: $white;
            text-align: left;
        }
    }
    tr {
        td {
            padding: $smallspacefix * 2 $smallspacefix * 1.5;
            text-align: left;
            &:last-child {
                a {
                    height: 100%;
                }
            }
            &:nth-child(6) {
                max-width: 200px;
            }
        }
        th {
            padding: $smallspacefix * 1.5;
            text-align: justify;
            vertical-align: bottom;
        }
        &:nth-child(even) {
            background: #f9f9f9;
        }
        &:nth-child(odd) {
            background: $bluelighter;
        }
        td.picto-action {
            display: flex;
            justify-content: flex-end;
        }
    }

    &.attachment-list {
        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &.notification-container {
        td {
            padding: $smallspacefix $smallspacefix * 1.5;
        }
    }
}

.pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: $spacefix;
    & > .btn-small {
        margin-right: $smallspacefix;
        &:last-child {
            margin-right: 0;
        }
    }
    .btn-small {
        &.round {
            background: $whitelighter;
            border: 1px $greylighter solid;
            @include transition-linear();
            &.active {
                background: $bluelighter;
                color: $blue;
            }
            &:hover {
                background: $bluelighter;
                border: 0px $greylighter solid;
                @include transition-linear();
            }
        }
        &.prev {
            .picto {
                margin-right: $smallspacefix;
            }
        }
        &.next {
            .picto {
                margin-left: $smallspacefix;
            }
        }
        &.prev, &.next {
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0);
            @include transition-linear();
            &:hover {
                box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);
                @include transition-linear();
            }
        }
    }
    .page-location {
        background: $whitelighter;
        @include transition-linear();
        &:hover {
            background: $bluelighter;
            @include transition-linear();
        }
    }
}

footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: $black;
    height: 50px;
    .col {
        color: $whitelighter;
        padding-right: $spacefix;
    }
}

.accordeon-abs {
    &.active {
        .accordeon-hidden {
            transform: scaleY(1);
            transform-origin: 50% 0%;
            @include transition-linear();
        }
    }
    .accordeon-hidden {
        position: absolute;
        top: 100%;
        transform: scaleY(0);
        transform-origin: 50% 0%;
        @include transition-linear();
        .row {
            background: $greylighter;
            color: $whitelighter;
        }
    }
}

// ==================================================
// SMALL ACTIONS
// ==================================================

.deletable-item {
    &.deleted {
        transform: scaleY(0);
        transform-origin: 0% 0%;
        height: 0;
        @include transition-linear();
    }
}

.d-none {
    display: none;
}

.disconnect_dropdown {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.dropdown-menu {
    display: none; /* Caché par défaut */
    position: absolute;
    top: 100%; /* S'affiche en dessous de la div */
    left: 0;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    border-radius: 4px;
    z-index: 1000;
}

.dropdown-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    min-width: 100px;
}

.dropdown-menu li {
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.dropdown-menu li:last-child {
    border-bottom: none;
}

.dropdown-menu li a {
    text-decoration: none;
    color: #333;
    display: block;
}

.dropdown-menu li:hover {
    background-color: #f0f0f0;
}

.btn{
    cursor: pointer;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 4px;
    display: inline-block;
    margin: 10px;

}
.btn:hover{
    background-color: #e0e0e0;
}


// ==================================================
// IMPORTS OF PAGES
// ==================================================

@import 'pages/login.scss';
@import 'pages/pageForms.scss';
@import 'pages/others-page.scss';
@import 'pages/pageQuestionnaire.scss';

textarea.form-control {
    min-height: 10rem!important;
}

.btn-disabled {
    background: $grey !important;
    color: $whitelighter !important;
    cursor: not-allowed !important;
}

.btn-disabled:hover {
    background: $grey !important;
    color: $whitelighter !important;
    cursor: not-allowed !important;
}

.btn-primary-custom{
    background: $blue !important;
    color: $whitelighter !important;
    border: 0.5px solid $black !important;
    border-radius: 2% !important;
    padding: 0.8rem 1.3rem !important;
}

.btn-primary-custom:hover{
    cursor: pointer !important;
    background: $bluedarker !important;
    color: $whitelighter !important;
    border: 0.5px solid $bluedarker !important;
    border-radius: 2% !important;
    padding: 0.8rem 1.3rem !important;
}

.btn-danger-custom{
    cursor: pointer !important;
    background: $red !important;
    color: $whitelighter !important;
    border: 0.5px solid $black !important;
    border-radius: 2% !important;
    padding: 0.8rem 1.3rem !important;
}

.btn-danger-custom:hover{
    background: $reddarker !important;
    color: $whitelighter !important;
    border: 0.5px solid $reddarker !important;
    border-radius: 2% !important;
    padding: 0.8rem 1.3rem !important;
}

.filters-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: flex-end;
}

.filters-container div {
    display: flex;
    flex-direction: column;
}

.mb-1{
    margin-bottom: 1rem;
}
