[data-page-name="login"]{
	display: flex;
	align-items: center;
	position: relative;
	background: url("/assets/images/©Fotolia-Mikael Damkier.jpg");
	background-size: cover;
	background-position: 50% 50%;
	-webkit-animation-name: diaporama;
	-webkit-animation-duration: 20s;
	-webkit-animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-direction: normal;
	-moz-animation-name: diaporama;
	-moz-animation-duration: 20s;
	-moz-animation-timing-function: linear;
	-moz-animation-iteration-count: infinite;
	-moz-animation-direction: normal;
	animation-name: diaporama;
	animation-duration: 20s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	animation-direction: normal;

	&:after{
		content: '';
		position: absolute;
		z-index: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.3);
	}
	&>.flex-row-container{
		width: 100%;
		position: relative;
		z-index: 1;
		.col:nth-child(1){
			flex-basis: 60%;
			display: flex;
			align-items: flex-start;
			.text-container{
				margin-left: 100px;
				max-width: 500px;
				.logo{
					margin-bottom: $spacefix * 4;
					max-width: 200px;
				}
				.maintit{
					position: relative;
					&:after{
						content: '';
						position: absolute;
						bottom: -15px;
						left: 0;
						background: $blue;
						height: 4px;
						width: 10%;
					}
				}
				p{color: white;}
				.btn-classic{margin-top: $spacefix;}
			}
		}
		.col:nth-child(2){
			flex-basis: 40%;
			.login-form{
				margin-right: 100px;
				background: $whitelighter;
				flex-wrap: nowrap;
				padding: ($spacefix * 4) ($spacefix * 2);
				h1{	margin-bottom: $spacefix;}
				button[type="submit"]{
					margin-top: $spacefix * 2;
				}
			}
		}
	}
	.alert-danger  {
		padding:5px;
		color: white;
		font-weight: bold;
	}
	.login-paragraph{
		padding-right: 210px;
		text-align: justify;
		margin-bottom: 14px;
        line-height: 2;
	}
}

@-webkit-keyframes diaporama {
	0%{background-image: url("/assets/images/©Fotolia-Mikael Damkier.jpg");}
	19%{background-image: url("/assets/images/©Fotolia-Mikael Damkier.jpg");}
	20%{background-image: url("/assets/images/©AdobeStock-pressmaster.jpg");}
	39%{background-image: url("/assets/images/©AdobeStock-pressmaster.jpg");}
	40%{background-image: url("/assets/images/©AdobeStock-Rawpixel.jpg");}
	59%{background-image: url("/assets/images/©AdobeStock-Rawpixel.jpg");}
	60%{background-image: url("/assets/images/©CTC.jpg");}
	79%{background-image: url("/assets/images/©CTC.jpg");}
	80%{background-image: url("/assets/images/©Fotolia-endostock.jpg");}
	100%{background-image: url("/assets/images/©Fotolia-endostock.jpg");}
}

@-moz-keyframes diaporama {
	0%{background-image: url("/assets/images/©Fotolia-Mikael Damkier.jpg");}
	19%{background-image: url("/assets/images/©Fotolia-Mikael Damkier.jpg");}
	20%{background-image: url("/assets/images/©AdobeStock-pressmaster.jpg");}
	39%{background-image: url("/assets/images/©AdobeStock-pressmaster.jpg");}
	40%{background-image: url("/assets/images/©AdobeStock-Rawpixel.jpg");}
	59%{background-image: url("/assets/images/©AdobeStock-Rawpixel.jpg");}
	60%{background-image: url("/assets/images/©CTC.jpg");}
	79%{background-image: url("/assets/images/©CTC.jpg");}
	80%{background-image: url("/assets/images/©Fotolia-endostock.jpg");}
	100%{background-image: url("/assets/images/©Fotolia-endostock.jpg");}
}

@keyframes diaporama {
	0%{background-image: url("/assets/images/©Fotolia-Mikael Damkier.jpg");}
	19%{background-image: url("/assets/images/©Fotolia-Mikael Damkier.jpg");}
	20%{background-image: url("/assets/images/©AdobeStock-pressmaster.jpg");}
	39%{background-image: url("/assets/images/©AdobeStock-pressmaster.jpg");}
	40%{background-image: url("/assets/images/©AdobeStock-Rawpixel.jpg");}
	59%{background-image: url("/assets/images/©AdobeStock-Rawpixel.jpg");}
	60%{background-image: url("/assets/images/©CTC.jpg");}
	79%{background-image: url("/assets/images/©CTC.jpg");}
	80%{background-image: url("/assets/images/©Fotolia-endostock.jpg");}
	100%{background-image: url("/assets/images/©Fotolia-endostock.jpg");}
}

